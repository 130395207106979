import axios from 'axios';

let prefix = "/firmware-update"

const FirmwareUpdateApi = {
	getFirmwareHistory(payload){
		return axios.get( prefix + "/list-history", payload);
	},	
}

export default FirmwareUpdateApi;