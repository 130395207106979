import axios from 'axios';

let prefix = "/ocpi/2.2/evse"

const EvseApi = {
	
	getEvseList(payload){
		return axios.get( prefix + "/listEvse", {params: payload})
	},
	archiveEvse(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createEvse(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateEvse(payload){
		return axios.post( prefix + "/update", payload)
	},
	resetOcpiConfigurationEvse(payload){
		return axios.post( prefix + "/resetOcpiConfiguration", payload)
	},
}

export default EvseApi;