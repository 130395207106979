import axios from 'axios';

let prefix = "/business-details"

const BusinessDetailApi = {
	
	getBusinessDetailList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	createBusinessDetail(payload){
		return axios.post( prefix + "/create", payload)
	},
	deleteBusinessDetail(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	updateBusinessDetail(payload){
		return axios.post( prefix + "/update", payload)
	},

}

export default BusinessDetailApi;