import axios from 'axios';

let prefix = "/ocpi/2.2/connectors"

const ConnectorApi = {
	
	getConnectorList(payload){
		return axios.get( prefix + "/listConnector", {params: payload})
	},
	getStandardRef(payload){
		return axios.get( prefix + "/standardRef", {params: payload})
	},
	getStatusList(payload){
		return axios.get( prefix + "/statusList", {params: payload})
	},
	archiveConnector(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createConnector(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateConnector(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default ConnectorApi;